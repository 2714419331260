<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangeMixin from "@/mixins/DateRangeMixin";
import ProjectsPerformanceMixin from "@/views/Reports/mixins/ProjectsPerformanceMixin";
import ProjectService from "@/services/ProjectService";
import AppCfg from "@/mixins/AppCfg";
import dayjs from "dayjs";

export default {
    name: "VProjectsPerformance",
    mixins: [DateRangeMixin, ProjectsPerformanceMixin, AppCfg],
    components: { DateRangePicker },
    data() {
        return {
            loading: true,
            reportType: "projects",
            data: {},
            statusView: "act",
            customer_id: "",
            project_model: "",
            invoice_setup: "all",
            dateRange: {
                startDate: null,
                endDate: null,
            },
        };
    },
    computed: {
        invoiceSetups() {
            return {
                all: this.$t("project_filter.invoice_setup_BOTH"),
                meg: this.$t("project_filter.invoice_setup_MEG"),
                projects: this.$t("project_filter.invoice_setup_PROJECTS"),
            };
        },
        projectModels: function () {
            const data = this.appCfgRead("Projects.project_model_dropdown");
            delete data[3];
            return data;
        },
        currentExportUrl: function () {
            let url = new URL(
                location.protocol +
                    "//" +
                    location.host +
                    "/v1/projects/projects-performance/projects"
            );
            url.searchParams.append("csv", 1);

            if (this.dateRange.startDate && this.dateRange.endDate) {
                url.searchParams.append(
                    "dateRange",
                    JSON.stringify({
                        startDate: dayjs(this.dateRange.startDate).format(),
                        endDate: dayjs(this.dateRange.endDate).format(),
                    })
                );
            }

            url.searchParams.append("statusFilter", this.statusView);

            if (this.project_model !== "") {
                url.searchParams.append("project_model", this.project_model);
            }

            if (
                Array.isArray(this.customer_id) &&
                this.customer_id.length > 0
            ) {
                this.customer_id.forEach((customerId) => {
                    url.searchParams.append("customer_id[]", customerId);
                });
            } else if (this.customer_id !== "") {
                url.searchParams.set("customer_id", this.customer_id);
            }

            if (this.invoice_setup !== "all") {
                url.searchParams.append("invoice_setup", this.invoice_setup);
            }

            return url.href;
        },
    },
    watch: {
        dateRange: function () {
            this.fetchData();
        },
        statusView: function () {
            this.fetchData();
        },
        customer_id: function () {
            this.fetchData();
        },
        project_model: function () {
            this.fetchData();
        },
        invoice_setup: function () {
            this.fetchData();
        },
    },
    created() {
        this.fetchCustomers();
        this.fetchData();
    },
    methods: {
        async fetchData() {
            try {
                this.loading = true;
                const { data } = await ProjectService.GET_projectsPerformance(
                    this.reportType,
                    {
                        dateRange: this.serializeDateRange(this.dateRange),
                        statusFilter: this.statusView,
                        ...(this.invoice_setup !== "all"
                            ? { invoice_setup: this.invoice_setup }
                            : {}),
                        ...(this.customer_id !== ""
                            ? { customer_id: this.customer_id }
                            : {}),
                        ...(this.project_model !== ""
                            ? { project_model: this.project_model }
                            : {}),
                    }
                );
                this.data = data.data;
            } catch (e) {
                console.log("fetchData", e);
            }
            this.loading = false;
        },
        resetSearch() {
            this.dateRange = {
                startDate: null,
                endDate: null,
            };
            this.statusView = "act";
            this.customer_id = "";
            this.project_model = "";
            this.invoice_setup = "all";
        },
    },
};
</script>

<template>
    <div class="v-projects-performance">
        <base-header class="pb-1" type=""
            ><bread-bag>
                <!-- Top-right menu -->
                <div class="row">
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'ReportsProjectsPerformance',
                            }"
                            :title="$t('projects_performance.TAB_cases')"
                        />
                    </div>
                    <div class="col">
                        <navi-block
                            :navigate="{
                                name: 'ReportsAdminProjectsPerformance',
                            }"
                            :title="$t('projects_performance.TAB_admin_cases')"
                        />
                    </div>
                </div> </bread-bag
        ></base-header>
        <div class="container-fluid">
            <div class="row card-wrapper">
                <div class="col-3">
                    <date-range-picker
                        class="range-picker"
                        ref="picker"
                        opens="right"
                        :timePicker="false"
                        :showWeekNumbers="true"
                        :autoApply="true"
                        v-model="dateRange"
                        :linkedCalendars="true"
                        :locale-data="getDateRangePickerLocale()"
                        :ranges="getDateRangePickerRanges()"
                    >
                        <template #header>
                            <manual-range
                                :date-range-manual="dateRangeManual"
                                :date-range-error="dateRangeError"
                                @exec="execManualRange('dateRange')"
                            />
                        </template>
                        <template
                            v-slot:input="picker"
                            style="min-width: 350px"
                        >
                            <template v-if="picker.startDate">
                                {{ $d(picker.startDate, "short") }}
                                -
                                {{ $d(picker.endDate, "short") }}
                            </template>
                        </template>
                    </date-range-picker>
                </div>
                <div class="col-3">
                    <FormulateInput
                        v-model="invoice_setup"
                        type="select"
                        :options="invoiceSetups"
                    />
                </div>
                <div class="col-6">
                    <FormulateInput
                        v-model="customer_id"
                        :placeholder="
                            $t('my_tasks.search_placeholder_customer')
                        "
                        type="multiselect"
                        :multiple="true"
                        :options="customers"
                    />
                </div>
                <div class="col-3">
                    <FormulateInput
                        v-model="project_model"
                        :placeholder="
                            $t('my_tasks.search_placeholder_case_type')
                        "
                        type="select"
                        :options="projectModels"
                    />
                </div>
                <div class="col-3">
                    <FormulateInput
                        v-model="statusView"
                        type="select"
                        :options="filterViews"
                    />
                </div>
                <div class="col-4">
                    <base-button
                        type="white"
                        @click="resetSearch"
                        :outline="false"
                        ><i class="far fa-redo"></i>
                        {{ $t("search.reset") }}</base-button
                    >
                    <a
                        :href="currentExportUrl"
                        class="btn btn-primary"
                        target="_blank"
                        >{{ $t("projects.download_as_csv") }}</a
                    >
                </div>
            </div>
            <div class="row card-wrapper mt-3">
                <div class="col-12">
                    <card body-classes="px-0">
                        <v-loader :loading="loading" v-if="loading" />
                        <div class="table-responsive" v-else-if="data">
                            <v-table
                                class="table table-mg table-sm table-striped"
                            >
                                <v-thead>
                                    <v-tr>
                                        <v-th>{{
                                            $t("projects.performance_rep.case")
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "projects.performance_rep.budget"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "projects.performance_rep.invoiced"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">{{
                                            $t(
                                                "projects.performance_rep.total_tasks"
                                            )
                                        }}</v-th>

                                        <v-th class="text-right">{{
                                            $t(
                                                "projects.performance_rep.glob_budget_perc"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right border-right">{{
                                            $t(
                                                "projects.performance_rep.glob_avg_hr_rate"
                                            )
                                        }}</v-th>
                                        <v-th class="text-right">
                                            {{
                                                $t(
                                                    "projects.performance_rep.total_tasks_period"
                                                )
                                            }}
                                        </v-th>
                                        <v-th class="text-right">
                                            {{
                                                $t(
                                                    "projects.performance_rep.period_budget_perc"
                                                )
                                            }}
                                        </v-th>
                                        <v-th class="text-right">
                                            {{
                                                $t(
                                                    "projects.performance_rep.period_avg_hr_rate"
                                                )
                                            }}
                                        </v-th>
                                    </v-tr>
                                </v-thead>
                                <v-tbody>
                                    <v-tr v-for="p in data" :key="p.project_id">
                                        <v-td
                                            ><router-link
                                                :to="{
                                                    name: 'ProjectDisplay',
                                                    params: {
                                                        project_id:
                                                            p.project_id,
                                                    },
                                                }"
                                                >{{
                                                    p.project_title
                                                }}</router-link
                                            >
                                            <span class="text-muted"
                                                >({{ p.project_id }})</span
                                            >
                                            <div class="small">
                                                <div class="text-muted">
                                                    {{ p.customer_name }}
                                                </div>
                                                <span class="">{{
                                                    p.project_model_name
                                                }}</span>
                                                |
                                                <span class="">{{
                                                    p.project_status_name
                                                }}</span>
                                                |
                                                <span>{{
                                                    p.invoice_setup_name
                                                }}</span>
                                            </div></v-td
                                        >
                                        <v-td class="text-right">
                                            <template
                                                v-if="
                                                    parseFloat(
                                                        p.budget_amount
                                                    ) > 0
                                                "
                                                >{{
                                                    $n(p.budget_amount, {
                                                        style: "currency",
                                                        currency: p.currency,
                                                        currencyDisplay: "code",
                                                    })
                                                }}</template
                                            ><template v-else
                                                >---</template
                                            ></v-td
                                        >
                                        <v-td class="text-right">
                                            <template
                                                v-if="
                                                    parseFloat(
                                                        p.total_invoiced
                                                    ) > 0
                                                "
                                            >
                                                {{
                                                    $n(p.total_invoiced, {
                                                        style: "currency",
                                                        currency: p.currency,
                                                        currencyDisplay: "code",
                                                    })
                                                }} </template
                                            ><template v-else>---</template>
                                        </v-td>
                                        <v-td class="text-right"
                                            >{{ p.tasks_time_global | toTime }}
                                            <div class="small text-muted">
                                                {{
                                                    $n(p.tasks_price_global, {
                                                        style: "currency",
                                                        currency: p.currency,
                                                        currencyDisplay: "code",
                                                    })
                                                }}
                                            </div>
                                        </v-td>

                                        <v-td class="text-right">
                                            <template
                                                v-if="
                                                    parseFloat(
                                                        p.budget_amount
                                                    ) > 0
                                                "
                                            >
                                                {{
                                                    $n(
                                                        p.global_perc_budget,
                                                        "onedec"
                                                    )
                                                }}&nbsp;%
                                            </template>
                                            <template v-else>---</template>
                                        </v-td>
                                        <v-td class="text-right border-right">{{
                                            $n(p.avg_hour_rate, {
                                                style: "currency",
                                                currency: p.currency,
                                                currencyDisplay: "code",
                                            })
                                        }}</v-td>
                                        <v-td class="text-right"
                                            >{{ p.tasks_time_period | toTime }}
                                            <div class="small text-muted">
                                                {{
                                                    $n(p.tasks_price_period, {
                                                        style: "currency",
                                                        currency: p.currency,
                                                        currencyDisplay: "code",
                                                    })
                                                }}
                                            </div>
                                        </v-td>
                                        <v-td class="text-right"
                                            ><template
                                                v-if="
                                                    parseFloat(
                                                        p.budget_amount
                                                    ) > 0
                                                "
                                            >
                                                {{
                                                    $n(
                                                        p.period_perc_budget,
                                                        "onedec"
                                                    )
                                                }}&nbsp;%
                                            </template>
                                            <template v-else
                                                >---</template
                                            ></v-td
                                        >
                                        <v-td class="text-right">{{
                                            $n(p.avg_hour_rate_period, {
                                                style: "currency",
                                                currency: p.currency,
                                                currencyDisplay: "code",
                                            })
                                        }}</v-td>
                                    </v-tr>
                                </v-tbody>
                            </v-table>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </div>
</template>


<style>
</style>
